.flop__support {
  height: auto;
  padding: 50px 0;
  position: relative;
  width: 100%;
  background: #a8a6a6;
  color: #000000;
  text-align: center;
  img {
    width: 200px;
    margin-top: 30px;
    margin-right: 30px;
  }
  .top__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 30%;

    p {
      text-align: center;
    }

    h1 {
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 1px;
      margin-bottom: 30px;
      text-transform: uppercase;
      color: #fff;
    }
  }

  .flop__support__card {
    cursor: pointer;
    margin-top: 50px;
    width: 200px;
    background: #333;
    padding: 30px 30px;
    border-radius: 1%;
    // -moz-box-shadow: 2px 2px 5px 0px #333;
    // -webkit-box-shadow: 2px 2px 5px 0px #333;
    // -o-box-shadow: 2px 2px 5px 0px #333;
    // box-shadow: 2px 2px 5px 0px #333;
    transition: transform ease-in-out 0.3s;
    &:hover {
      transform: scale(1.1);
    }
    img {
      width: 100px;
      margin-top: 30px;
    }
    h3 {
      font-weight: bold;
      font-size: 20px;
      color: #fff;
    }
    p {
      color: #5c5c5c;
      font-weight: 500;
      margin-top: 10px;
    }
    .flop__support__card__text {
      margin-top: 50px;
    }
  }
}
