@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,700,800,900&display=swap");

$green: #0c0;
$red: #ff0000;
html  {
    scroll-behavior: smooth;
}
* {
    font-family: "Poppins", sans-serif;
    margin: 0;
    padding: 0;
}

.logo-font {
    font-family: "flog", sans-serif;
}

.flop__red {
    color: $red;
}

.flop__green {
    color: $green;
}

.flop__btn {
    text-decoration: none !important;
    color: #00cc00;
    text-transform: uppercase;
    padding: 15px 15px;
    border: 2px solid #00cc00;
    transition: 0.5s ease-in-out;
    display: inline-block;

    &:hover {
        color: #fff;
        background: #00cc00;
        transform: translateY(-10px);
    }
}

.framagit-btn {
    background: #2A2961;
    text-decoration: none;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: .6s ease-in-out;

    &:hover {
        text-decoration: none;
        color: #fff;
        background: #4B4CA3;
    }
}

.donate-btn {
    background: #FA5851;
    text-decoration: none;
    color: #fff;
    padding: 8px 16px;
    border-radius: 5px;
    transition: .6s ease-in-out;

    &:hover {
        text-decoration: none;
        color: #fff;
        background: rgb(255, 130, 125);
    }
}
