@import "../../theme/theme.scss";

$footerBackground: #2a2a2a;
$footerColor: #c9ccdb;

footer {
    padding: 50px 0;
    background: $footerBackground;
    color: $footerColor;
    font-size: 16px;
    font-weight: 300;

    h4 {
        color: #fff;
        font-weight: 700;
        letter-spacing: 1px;
        margin-bottom: 10px;
        height: auto;
        font-size: 16px;
    }

    a {
        color: $footerColor;
        transition: .6s ease-in-out;
        text-decoration: none !important;
        
        &:hover {
            color: #0c0;
        }
    }

    ul {
        list-style: none;
    }
}

.footer-bottom {
    background: #333;
    height: 50px;
    font-size: 14px;
    width: 100%;
    color: $footerColor;
    font-weight: 300;
    text-align: center;
}
