@import '../../theme/theme.scss';

.menu-icon {
    visibility: hidden;
    display: inline-block;
    cursor: pointer;
    position: absolute;
    right: 50px;
  
    &:hover {
      .bar1,
      .bar2,
      .bar3 {background: $green}
    }
  
  
    .bar1,
    .bar2,
    .bar3 {
      width: 35px;
      height: 2px;
      background-color: #2a2a2a;
      margin: 8px 0;
      transition: 0.4s;
    }
  
    &.change {
      .bar1 {
        -webkit-transform: rotate(-45deg) translate(-6px, 6px);
        transform: rotate(-45deg) translate(-6px, 6px);
      }
      .bar2 {
        opacity: 0;
      }
      .bar3 {
        -webkit-transform: rotate(45deg) translate(-6px, -6px);
        transform: rotate(45deg) translate(-8px, -8px);
      }
    }
  }
  