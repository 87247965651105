@import '../../theme/theme.scss';

.flop__screenshots{
    h1 {
      text-align: center;
      font-size: 40px;
      font-weight: bold;
      letter-spacing: 1px;
      margin-bottom: 30px;
    }

    p{
      text-align: center;
    }

    img {
      max-width: 100%;
    }
}