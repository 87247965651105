.flop__presentation {
    width: 100%;
    position: relative;

    .wave {
      position: absolute;
      top: 0;
      z-index: -1;
    }
   
    .flop__presentation__content {
      width: 100%;
      padding: 50px 0;
      text-align: center;

      .top__content {
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 30%;
        margin-bottom: 60px;
  
        h1 {
          font-size: 40px;
          font-weight: bold;
          letter-spacing: 1px;
          margin-top: 60px;
          margin-bottom: 30px;
          text-align: center;
        }
  
        p {
          text-align: center;
          padding-bottom: 15px;
        }
      }
  
      .bottom__content {
        width: 100%;
        height: 70%;
        text-align: left;

        i {
          font-size: 35px;
          color: #00cc00;
        }
  
        .attribute {
          margin: 5px;
          margin-bottom:20px;
   
          h4 {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 20px;
          }
          p {
            font-size: 16px;
            font-weight: 300;
          }
        }
      }
  
      .flop__situation {
        text-transform: uppercase;
        font-size: 18px;
        letter-spacing: 1px;
        font-weight: bold;
        text-align: center;
      }
    }
  }
  