.privacy {
    font-weight: 300;
    .top {
        h1 {
            color: #fff;
            font-weight: bold;
        }
        background: #2a2a2a;
        padding: 50px 0;
        color: #c9ccdb;
    }

    ul {
        li {
            margin-left: 20px;
        }
    }
}
