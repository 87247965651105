@import "../../theme/theme.scss";

.testimonials {
    text-align: center;
    padding: 50px 0;
    h1 {
        text-transform: uppercase;
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-bottom: 30px;
    }

    .testimonial {
        border: 2px solid rgba(122, 122, 122, 0.082);
        border-radius: 5px;
        padding: 15px 15px;
        text-align: left;

        i {
            font-size: 28px;
        }
    }
}
