@import "../../theme/theme.scss";

.flop__contributors {
    padding-top: 50px;
    min-height: 100vh;

    h1{
        color: $green;
    }

    .contributors__header {
        margin-bottom: 20px;
    }

    .contributor__card {
        margin: 10px 0px 10px 15px;
        transition: 0.5s ease-in-out;
        &:hover {
            color: $green;
        }
    }
}
