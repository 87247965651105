.not-found {
    padding: 50px 0;
    h1 {
        text-transform: uppercase;
        font-weight: bold;
        font-size: 100px;
    }

    span {
        font-size: 20px;
    }

    p {
        font-weight: 300;
    }
}