@import "../../theme/theme.scss";

.blog {
    .blog-header {
        h1 {
            color: #fff;
            font-weight: bold;
        }
        background: #2a2a2a;
        padding: 50px 0;
        color: #c9ccdb;
    }

    .post {
        padding: 20px;
        border-radius: 5px;

        img {
            max-width: 100%;
        }

        h4 {
            font-size: 20px;
        }
    }
}

.article {
    .thumbnail {
        max-width: 100%;
    }

    blockquote {
        padding-left: 5px;
        border-left: 5px solid #00cc00;
    }

    ol {
        font-weight: 300;
    }

    li {
        margin-left: 40px;
    }

    h1 {
        font-weight: bold;
    }

    p {
        font-weight: 300;
    }

    pre {
        background: #2a2a2a;
        border-radius: 5px;
        padding: 15px;
        color: white;
    }

    img {
        max-width: 100%;
    }

    .links {
        ul {
            list-style: none;
        }
    }
}
