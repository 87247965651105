@import "../../theme/theme.scss";

.flop__questions {
    padding: 50px 0;
    background: #333333;
    color: #c9ccdb;

    .card {
        background: #2a2a2a;
        p {
            text-align: left;
        }
    }

    h1 {
        text-align: center;
        color: #fff;
        text-transform: uppercase;
        font-size: 40px;
        font-weight: bold;
        letter-spacing: 1px;
        margin-bottom: 30px;
    }

    p {
        text-align: center;
    }

    .btn-link {
        color: $green;
    }
}
