@import '../../theme/theme.scss';

.landing {
    min-height: 100vh;
    position: relative;
    .wave {
        position: absolute;
        bottom: 0;
        z-index: -1;
    }

    .view-more {
        position: absolute;
        z-index: 10;
        bottom: 20px;
        color: #fff;
        border: 2px solid #fff;
        padding: 8px 16px;
        text-transform: uppercase;
        text-decoration: none;
        transition: .7s ease-in-out;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 5px;

        &:hover {
            color: $green;
            background: #fff;
        }
    }

    .outer {
        height: 100vh;
    }

    .right {
        h1 {
            font-size: 24px;
            font-weight: bold;
        }

        .separator {
            margin: 10px 0;
            border-radius: 50px;
            width: 100px;
            background: #0c0;
            height: 5px;
        }
    }
}

.flop-laptop {
    width: 100%;
    max-width: 1200px;
    position: relative;

    .laptop {
        width: 100%;
    }

    .screen {
        position: absolute;
        width: 73.1%;
        height: 92%;
        background: #000;
        top: 3.5%;
        left: 14.8%;
        z-index: -1;
    }
}
